.bonus-t {
    height: 50px;
    display: table;
    margin: 50px 0;

}
.bonus-b {
    font-weight: bold;
}
.ico-18 {
    width: 18px;
    height: 18px;
}
.bonus-s {
    margin: 7px 0;
}
.bonus-c1 {
    display: table-cell;
    vertical-align: top;
}
.bonus-c2 {
    display: table-cell;
    vertical-align: middle;
}
.bonus-c3 {
    vertical-align: middle;
}
.bonus-d1 {
    font-size: 1.8em;
}
.currencyConv {
    font-size: 0.5em;
}
.conditions {
    font-size: 0.7em;
}
.oldBonuses {
	color: #282C34;
    text-align: center;
}
.fullscreen{
	position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 10px;
    z-index: 100;
}
