$-num_of_items: 3;
$-test: '-';

@function num_of_items() {
  @return $num_of_items or $-num_of_items;
}

@function test() {
  @return $test or $-test;
}

$item-width: 500px;
$item-separation: 80px;
$p: 1200px;
$t: 2 * 3.141592653589793 / num_of_items();
$apo: 700px;

.carousel-base{
  //-test: test();
  num_of_items: num_of_items();
  margin: 0 auto;
  overflow: hidden;
  perspective: $p;
  //width: 70rem;

  //background: darkslategrey;
  color: #fff;
	display: flex;
  flex-direction: column;
  font: normal normal 20px/1.3333 Arial, Helvetica, sans-serif;
  //height: 100vh;
  justify-content: center;
	margin: 0;

  width: 100%;
  	max-width: 750px;
  	height: 450px;
  	//display: block;
  	//box-shadow: 0 1px 0 #ccc, 0 1px 0 1px #eee;
  	border-radius: 2px;
  	margin-left: auto;
  	margin-right: auto;
  	//background: #DDD url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2212%22%20viewBox%3D%220%200%2044%2012%22%3E%3Ccircle%20cx%3D%226%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3Ccircle%20cx%3D%2222%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3Ccircle%20cx%3D%2238%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3C%2Fsvg%3E') 4px 4px no-repeat;
  	//padding: 20px 0 0 0;
  	position: relative;
    border: 1px solid white;
	.cnav{
		display: flex;
		justify-content: center;
		margin: 8px 0 0;
    position: absolute;
    bottom: 20px;
    margin: auto;
    width: 100%;

		.nav-button{
			background: none;
			border: 1px solid;
      border-radius: 4px;
      color: white;
      opacity: 0.5;
      cursor: pointer;
      flex: 0 0 auto;
			letter-spacing: 1px;
      margin: 0 16px;
      outline: none;
			padding: 8px 12px;
      transition: all 0.3s;

      &:hover{ background: rgba(255, 255, 255, 0.2); opacity: 0.9 }
      &:active{ opacity: 1 }
    }
	}
}

.nSafari {
  .carousel {
    .carousel-items {
      -webkit-transform-box: fill-box;
      transform-box: fill-box;
      -webkit-transform: translate3d(0, 0, 0);
      transform-origin: 50% 50% (-$apo);
      .carousel-item{
        opacity: 0.3;
        &:not(:first-of-type) {
          transform-origin: 50% 50% (-$apo);				
        }
      }

    }
  }
}
.carousel{
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 1rem 0 3rem 0;
	perspective: $p;
  position: relative;
  transform: rotateX(-15deg);

	> *{ flex: 0 0 auto; }
  
	.carousel-items{
		margin: 0;
		transform-style: preserve-3d;
		transition: transform 0.5s;
		width: $item-width;    

		a {
		  color: white;
		  text-decoration: none; /* no underline */
		}

		a:hover {
			text-shadow:
	          0 0 4px rgba(255, 255, 255, 0.2),
	          0 0 8px rgba(255, 255, 255, 0.2),
	          0 0 16px rgba(255, 255, 255, 0.2);
		}

		.carousel-item{
			box-sizing: border-box;
			opacity: 0;
			padding: 0;
      text-align: center;
      transition: all 0.5s;
			width: 100%;
      //scale: 0.5;

      &[data-current=yes]{
        opacity: 1;
        text-shadow:
          0 0 4px rgba(255, 255, 255, 0.5),
          0 0 8px rgba(255, 255, 255, 0.5),
          0 0 16px rgba(255, 255, 255, 0.5);

        img {
          box-shadow:
            0 0 4px rgba(255, 255, 255, 0.5),
            0 0 8px rgba(255, 255, 255, 0.5),
            0 0 16px rgba(255, 255, 255, 0.5);
        }

        .carousel-item-container{ transform: rotateY(0deg); }

        .span-me span{
          animation: spans 0.5s forwards;
          animation-timing-function: ease-in-out;
          display: inline-block;
          opacity: 0;
          position: relative;
          transform: translateY(30%); }

          @for $i from 1 through 20 {
            .span-me span:nth-child(#{$i}) {
              animation-delay: #{$i * 0.05}s;
              z-index: #{$i * -1};
             }
          }

          small{
            opacity: 1;
            transition: all 2s;
            transform: translateY(0); }
       }

      .carousel-item-container{
         transform: rotateY(180deg);
         transition: all 0.5s;
      }

      small{
        display: inline-block;
        font-size: 1.3em;
        font-weight: normal;
        opacity: 0;
        transform: translateY(50%);
        transition: all 0.5s; }

      h2{
        //font-size: 4em;
        margin: 0 0 10px 0;
        letter-spacing: -1px;
        line-height: 1;
        //margin: 0;
      }

			&:not(:first-of-type) {
				position: absolute;
				left: 0;
				top: 0;
			}

			@for $i from 2 through num_of_items() {
				&:nth-child(#{$i}) {
					transform: rotateY(#{($i - 1) * $t}rad);
				}
			}
		}
	}
}

@keyframes spans{
  70% { opacity: 1; transform: translateY(-10%); }
  100% { opacity: 1; transform: translateY(0%); }
}
