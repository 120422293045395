.item-media {
	position: relative;
}
.item-media-top {
	position: relative;
    margin: auto;
    //padding: 20px;
}
.item-media-left {
	position: relative;
	float: left;
	margin: 30px;
}
.media-40, .media-preview40 {
	min-width: 40px;
	width: 40px;
	height: 40px;
	margin-top: 5px;
	margin-bottom: 5px;
	border-radius: 50px;
	//.background-size(cover) !important;
	background-size: cover;
}

.media-80, .media-preview80 {
	min-width: 80px;
	width: 80px;
	height: 80px;
	margin-top: 5px;
	margin-bottom: 5px;
	border-radius: 50px;
	//.background-size(cover) !important;
	background-size: cover;
}
