@import "mixins/flexbox";
@import "mixins/columns";
@import "variables";
@import "elements";
@import "landing-page";
@import "layout";
@import "pricing";
@import "staff";
@import "contact";
@import "blog";
@import "referrer";
@import "forms";
@import "navigation";
@import "footer";
@import "cloudcannon";
$num_of_items: 3;
//$test: 'main';
@import 'carousell';
@import 'kwk-specifics';
